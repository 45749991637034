<!--
 * @Author: 智客云网络科技
 * @Date: 2021-10-17 10:30:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:05:51
 * @Description: 权限管理
 * @FilePath: \src\views\Frame\Auth\Right\RightManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="success"
        icon="el-icon-plus"
        @click="_OpenNewRightModel()"
        >创建新权限</el-button
      >
      <el-button
        size="small"
        type="warning"
        icon="el-icon-refresh"
        @click="_Load()"
        >重载数据</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="mini"
      stripe
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      row-key="right_id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="80px"
      ></el-table-column>

      <!-- 权限名称 -->
      <el-table-column
        label="权限名称"
        prop="name"
        align="center"
        show-overflow-tooltip
        width="180px"
      ></el-table-column>

      <!-- 权限类型 -->
      <el-table-column label="权限类型" align="center" width="120px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.group_id === ''"
            >————</el-tag
          >
          <el-tag size="small" effect="plain" v-else-if="s.row.type == 103501"
            >菜单</el-tag
          >
          <el-tag
            size="small"
            effect="plain"
            type="warning"
            v-else-if="s.row.type == 103502"
            >Api接口</el-tag
          >
          <el-tag
            size="small"
            effect="plain"
            type="info"
            v-else-if="s.row.type == 103503"
            >常规权限</el-tag
          >
          <el-tag size="small" effect="plain" type="danger" v-else>未知</el-tag>
        </template>
      </el-table-column>

      <!-- 权限状态 -->
      <el-table-column label="状态" align="center" width="80px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.status == 1"
            >启用</el-tag
          >
          <el-tag size="small" effect="plain" type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>

      <!-- 描述 -->
      <el-table-column label="描述">
        <template slot-scope="s"
          ><span>{{ s.row.notes }}</span></template
        >
      </el-table-column>

      <!-- 创建日期 -->
      <el-table-column label="创建日期" align="center" width="135px">
        <template slot-scope="s"
          ><span>{{ s.row.add_time }}</span></template
        >
      </el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="left" width="300px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateRightModel(s.row.right_id, s.row.group_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            v-if="s.row.group_id !== ''"
            @click="_DelRight(s.row)"
            >删除</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            v-else
            @click="_DelRightGroup(s.row)"
            >删除</el-button
          >
          <el-button
            size="mini"
            icon="el-icon-document"
            v-if="s.row.type == 103502 || s.row.type == 103503"
            @click="_CopyRightId(s.row.right_id)"
            >复制ID</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[10, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-right ref="nu-right"></nu-right>
  </div>
</template>

<script>
import { getRightTable, delRight, delRightGroup } from "../api"; //页面专有接口
import NuRight from "./NURight";
export default {
  name: "RightManage", //对应路由地址
  components: {
    NuRight,
  },
  data() {
    return {
      p: {
        pageNo: 1, //当前页
        pageSize: 10, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], // 数据集合
    };
  },
  methods: {
    //载入数据
    _Load() {
      getRightTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
      })
        .then((res) => {
          this.TableData = res.data.data; // 重写数据
          this.p.dataCount = res.data.dataCount; // 重写数据行数
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 103045) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开组织创建组件
    _OpenNewRightModel() {
      this.$refs["nu-right"].open(0);
    },

    //打开组织修改组件
    _OpenUpdateRightModel(id, type) {
      if (type === "") {
        type = 1;
      } else {
        type = 2;
      }
      this.$refs["nu-right"].open(id, type);
    },

    /**
     * 删除权限
     */
    _DelRight(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除权限【" +
          data.name +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delRight({
            kr_id: data.right_id,
          })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },

    /**
     * 删除权限分组
     */
    _DelRightGroup(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除权限分组【" +
          data.name +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delRightGroup({
            krg_id: data.right_id,
          })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },

    //复制权限ID
    _CopyRightId(id) {
      this.$utils.copyText(id);
      this.$utils.msg("权限ID复制成功!");
    },
  },
  mounted() {
    this._Load();
  },
};
</script>
