<!--
 * @Author: 智客云网络科技
 * @Date: 2021-10-17 10:31:31
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:38
 * @Description: 创建 修改 权限
 * @FilePath: \src\views\Frame\Auth\Right\NURight.vue
-->

<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item label="权限名称：" style="width: 90%">
            <el-input
              size="small"
              v-model="m.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="权限类型：" v-if="id === 0" style="width: 90%">
            <el-radio
              v-model="m.type"
              v-if="rightGroupList.length !== 0"
              :label="103503"
              >常规权限</el-radio
            >
            <el-radio
              v-model="m.type"
              v-if="rightGroupList.length !== 0"
              :label="103501"
              >菜单权限</el-radio
            >
            <el-radio
              v-model="m.type"
              v-if="rightGroupList.length !== 0"
              :label="103502"
              >接口权限</el-radio
            >
            <el-radio v-model="m.type" :label="103500">权限分组</el-radio>
          </el-form-item>

          <el-form-item
            label="权限分组："
            v-if="m.type !== 103500"
            style="width: 90%"
          >
            <el-select size="small" v-model="m.group_id">
              <!-- <el-option label="跟权限"
                         :value="'0'"></el-option> -->
              <el-option
                v-for="item in rightGroupList"
                :key="item.kr_group_id"
                :label="item.krg_name"
                :value="item.kr_group_id"
              >
                <i :class="item.icon"></i> {{ item.krg_name }}
              </el-option>
            </el-select>
            <el-tooltip
              class="item"
              effect="dark"
              content="刷新列表"
              placement="top-start"
            >
              <el-button
                size="small"
                icon="el-icon-refresh"
                @click="_GetAllRightGroup()"
              ></el-button>
            </el-tooltip>
          </el-form-item>

          <el-form-item label="显示排序：" style="width: 45%">
            <el-input-number
              v-model="m.sort"
              size="small"
              :min="1"
              :max="999999"
            ></el-input-number>
          </el-form-item>

          <el-form-item
            label="权限状态："
            v-if="m.type !== 103500"
            style="width: 30%"
          >
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <div class="from-notes-box">
            <el-form-item label="描述：" style="width: 90%; display: block">
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider></el-divider>

        <!-- 创建/修改权限 -->
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateNewRight()"
          v-if="id == 0 && m.type !== 103500"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateRight()"
          v-else-if="m.type !== 103500"
          >保存</el-button
        >

        <!-- 创建/修改权限分组 -->
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateNewRightGroup()"
          v-if="id == 0 && m.type === 103500"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateRightGroup()"
          v-else-if="m.type === 103500"
          >保存</el-button
        >

        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>
<script>
import {
  createNewRight,
  createNewRightGroup,
  updateRight,
  updateRightGroup,
  getAllRightGroup,
  getRightInfo,
  getRightGroupInfo,
} from "../api"; //页面专有接口
export default {
  data() {
    return {
      m: {
        name: "",
        type: 103503,
        group_id: "",
        sort: 1,
        status: 1,
        notes: "",
      },
      id: 0, //
      init: false,
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      rightGroupList: [], //权限分组列表
    };
  },
  methods: {
    //打开组件
    open(id, type) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      if (id === 0) {
        //新建
        this._GetAllRightGroup(); //获取所有权限分组列表
        this.title = "新建权限";
        this.loading = false;
      } else {
        //修改
        this.title = "修改权限";
        if (type === 1) {
          this.m.type = 103500;
          this._GetRightGroupInfo(id);
        } else {
          this._GetAllRightGroup(); //获取所有权限分组列表
          this.$nextTick(() => {
            this._GetRightInfo(id); //获取权限信息
          });
        }
      }
    },

    //创建新权限
    _CreateNewRight() {
      var m = this.m;
      createNewRight({
        krg_id: m.group_id, //分组ID
        name: m.name, //名称
        sort: m.sort, //排序
        type: m.type, //类型
        status: m.status, //状态
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //创建新权限分组
    _CreateNewRightGroup() {
      var m = this.m;
      createNewRightGroup({
        name: m.name, //权限分组名称
        sort: m.sort, //显示排序
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改权限
    _UpdateRight() {
      var m = this.m;
      updateRight({
        kr_id: this.id, //ID
        krg_id: m.group_id, //分组ID
        name: m.name, //名称
        sort: m.sort, //排序
        status: m.status, //状态
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改权限分组
    _UpdateRightGroup() {
      var m = this.m;
      updateRightGroup({
        krg_id: this.id, //分组ID
        name: m.name, //分组名称
        sort: m.sort, //排序
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取权限分组列表
    _GetAllRightGroup() {
      getAllRightGroup()
        .then((res) => {
          this.rightGroupList = Object.values(res.data); //写入权限分组列表
          if (this.id === "0") {
            this.m.group_id = res.data[0].kr_group_id; //重置分组选项
          } else {
            if (this.init) {
              this.m.group_id = res.data[0].kr_group_id; //重置分组选项
            } else {
              this.init = true;
            }
          }
        })
        .catch((err) => {
          if (err.code !== 103003) {
            this.isShow = false;
            this.$utils.error(err.err_msg);
          }
          this.m.type = 103500;
        });
    },

    //获取指定权限参数
    _GetRightInfo(id) {
      getRightInfo({
        kr_id: id,
      })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.group_id = d.kr_group_id; //分组ID
          m.name = d.kr_name; //名称
          m.sort = d.kr_sort; //排序
          m.status = d.kr_status; //状态
          m.notes = d.kr_notes; //描述
          this.loading = false;
        })
        .catch((err) => {
          this.isShow = false;
          this.$utils.error(err.err_msg);
        });
    },

    //获取指定权限分组参数
    _GetRightGroupInfo(id) {
      getRightGroupInfo({
        krg_id: id,
      })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.name = d.krg_name; //权限分组名称
          m.sort = d.krg_sort; //显示排序
          m.notes = d.krg_notes; //描述
          this.loading = false;
        })
        .catch((err) => {
          this.isShow = false;
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
};
</script>
